/*
 * File:        site-wide.css
 * Author:      Martin Dwyer
 * Date:        March 1, 2020
 * Description: This file is part of the BargainBuyClub application.
 * License:     The application is provide herein under the GNU General Public
 *              License, a free copyleft license for software.  A copy of this
 *              license has been provided in the root folder of this application.
 */

/* Styles for elements appearing throughout the website */

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500;600;700&display=swap');

@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

body {
  font-family: 'Nunito Sans', sans-serif;
  background-color: #f2f2f2;
}

.logo-font {
  font-family: 'MuseoModerno', cursive;
  font-weight: 800;
  margin-left: 0.5rem;
  font-size: 1.6rem;
  color: $danger;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Josefin Sans', sans-serif;
  color: #ff0202;
}

a {
  text-underline-offset: 0.2rem;
  font-weight: 600;
}

footer a {
  color: #90ee90;
}

#navbar {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 1030;
}

main {
  padding: 75px;
  margin-top: 0px;
}

.navbar-brand {
  font-family: 'Mountains of Christmas';
  color: #ffffff;
}

#block_content {
  height: 80vh;
}

#bottom_nav {
  position: fixed;
  bottom: 0;
  z-index: 2;
  width: 100%;
}

header {
  margin-top: 15px;
  margin-bottom: 25px;
}

#page-title {
  width: auto;
  display: inline;
  font-size: 200%;
  font-weight: bolder;
  margin-left: 25px;
}

.hidden-element {
  display: none;
}

.error-message {
  color: red;
  font-style: italic;
  font-size: 75%;
}

.btn {
  font-size: 75%;
  color: white;
  width: auto;
  display: inline-block;
  margin-right: 15px;
  float: left;
  transition: 1s;

  &:hover {
    transform: scale(1.1);
    background-color: $dark;
    color: $white;
    font-weight: bold;
    box-shadow: 0 0 1rem rgba(#ff0202, 0.8);
  }
}

.clear-float {
  clear: both;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

nav {
  margin-bottom: 25px;
}

.inline-text-image {
  height: 20px;
  width: auto;
}

.row {
  margin: 0 0 0 0;
}

/* Footer */
.card-body > footer {
  text-align: center;
}

.buy-now {
  text-decoration: none;
  font-family: 'Mountains of Christmas';
  color: #ff0202;
}

.buy-now:hover {
  text-decoration: none;
  font-family: 'Mountains of Christmas';
  color: #009933;
}

/* Styles related to auto logoff jQuery dialog box */

button.ui-dialog-titlebar-close {
  display: none;
}

#ui-id-1 {
  text-align: center;
  font-family: 'Mountains of Christmas';
  color: #ff0202;
  font-size: 140%;
  font-weight: bold;
}

div.ui-dialog-buttonset > button {
  color: #ffffff;
  font-family: 'Mountains of Christmas';
  font-size: 110%;
  font-weight: bold;
  background-color: #ff0202;
  border: 2px solid #ff0202;
  width: 150px;
  height: auto;
}

#timeout {
  font-family: 'Sen';
}

#timeout {
  display: none;
}

footer .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

footer div:nth-child(1) {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

footer .row a {
  width: 120px;
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
  text-decoration: none;
  color: #fff;
}

footer .row p {
  font-size: 12px;
  color: #fff;
}

footer .row a:hover {
  color: #0000ff;
}

.card.fixed-bottom .card-body {
  padding: 0;
}

@keyframes rotateElement {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}

/* 
 * File:        media-queries.css
 * Author:      Martin Dwyer
 * Date:        March 1, 2020
 * Description: This file is part of the BargainBuyClub application.
 * License:     The application is provide herein under the GNU General Public 
 *              License, a free copyleft license for software.  A copy of this 
 *              license has been provided in the root folder of this application.
 */

/* Styles for adjusting to different display size */

@media (max-width: 575.98px) { 

    #home-jumbo h1 {
        display: none;

    }
    
    .user-button {
        clear:both;
        width: 85px;
        margin: 0 0 10px 0;
    }

 
}

@media (max-width: 800px) { 

    #home-left-column {
        border: none;
    }

    #mobile-hr-addition {
        display: inline-block;
    }

    #signin {
        max-width: 300px;
        margin: 0 auto;
    }

    #signup {
        max-width: 300px;
        margin: 0 auto;
    }

    #shopper-home img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #shopper-home h2 {
        text-align: center;
    }
    
       #display-alerts-table tr td:nth-child(1) {
        width: auto;
    }


    #display-alerts-table tr td:nth-child(2) {
        width: auto;
    }


    #display-alerts-table tr td:nth-child(3) {
        width: auto;
    }


    #display-alerts-table tr td:nth-child(4) {
        width: auto;
    }

    #display-alerts-table tr td:nth-child(5) {
        width: auto;
    }

    


}





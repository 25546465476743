.not-found {
  height: 70vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $dark;

  h2 {
    margin-bottom: 3rem;
  }

  a {
    color: $secondary;
    border-color: $secondary;
  }

  & > p:nth-child(5) {
    margin-top: 3rem;
  }
}

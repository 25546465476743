/*
 * File:        display-alerts.css
 * Author:      Martin Dwyer
 * Date:        March 1, 2020
 * Description: This file is part of the BargainBuyClub application.
 * License:     The application is provide herein under the GNU General Public
 *              License, a free copyleft license for software.  A copy of this
 *              license has been provided in the root folder of this application.
 */

/* Styles for elements on displayAlerts.jsp */

#display-alerts-page {
  min-height: 75vh;
}

.user-button {
  width: auto;
  display: inline-block;
  float: left;
  margin-right: 10px;
}

#display-alerts-table tr th:nth-child(3) {
  text-align: right;
}

#display-alerts-table tr th:nth-child(4) {
  text-align: right;
}

#display-alerts-table tr th:nth-child(5) {
  text-align: right;
}

#display-alerts-table tr td:nth-child(1) {
  width: 10%;
}

#display-alerts-table tr td:nth-child(2) {
  width: 50%;
}

#display-alerts-table tr td:nth-child(3) {
  width: 15%;
  text-align: right;
}

#display-alerts-table tr td:nth-child(4) {
  width: 15%;
  text-align: right;
}

#display-alerts-table tr td:nth-child(5) {
  width: 10%;
  text-align: center;
}

#display-alerts-table button {
  width: 6rem;

  &.btn-info {
    background-color: gray;
    border-color: gray;
  }
}

#is-admin {
  display: none;
}

#admin-active {
  display: none;
}

#edit-alert-button-div {
  margin-top: 15px;
  clear: both;
  float: left;
  display: none;
}

#edit-alert-button-div label {
  margin-bottom: 15px;
  width: 165px;
}

#edit-alert-button-div select {
  display: inline-block;
  margin-bottom: 15px;
  width: 100px;
}

#edit-alert-button {
  margin-bottom: 300px;
}

#delete-alert-button-div {
  clear: both;
  float: left;
  margin-top: 15px;
  display: none;
}

#delete-alert-button-div label {
  display: inline-block;
  clear: both;
  margin-left: 25px;
  margin-bottom: 15px;
}

#delete-alert-button-div select {
  display: inline-block;
  margin-bottom: 15px;
  width: 100px;
  float: left;
}

#delete-alert-button {
  margin-bottom: 300px;
}

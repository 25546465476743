#back-to-top {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 1000;
  background-color: transparent;

  button {
    background: transparent;
    border: none;
    font-size: 2rem;
  }

  i {
    background: transparent;
    color: $secondary;
  }
}

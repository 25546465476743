.about .admin,
.about .upcoming,
.about .carousel,
.about .process,
.about .goal {
  max-width: 1200px;
  margin: 3rem auto;

  p {
    font-size: 1.3rem;
  }
}

.about .goal,
.about .process,
.about .upcoming {
  p,
  li {
    font-size: 1.3rem;
  }

  li {
    padding-left: 2rem;
  }
}

.about .carousel {
  max-width: 700px;
}

.about .carousel .carousel-inner {
  width: 90%;
  margin: 0 auto;
}

.about .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  position: absolute;
  left: 0;
}

.about .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  position: absolute;
  right: 0;
}

.about .carousel-caption {
  color: black;
}

.carousel {
  overflow: hidden;
  padding-bottom: 4rem;
}

.carousel-caption {
  top: 100%;
}

.carousel-inner {
  overflow: visible;
  padding-bottom: 4rem;
}

.carousel-control-next,
.carousel-control-prev {
  bottom: 4rem;
}

.carousel-indicators {
  bottom: 1rem;
}

.carousel-indicators [data-bs-target] {
  background-color: #333333;
}
